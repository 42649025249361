import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Image from '../components/Lendis/Image'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import Header from '../components/Header'

const NotFoundPage = () => (
  <Layout>
    <Header page="404" />
    <Menu />
    <div className="bg-white mx-auto container pt-32 ">
      <div className="flex flex-wrap justify-center text-center mt-20">
        <div className="">
          <Image
            className="float-right mr-10"
            src="v1573116640/website/angry-panda_t5kgsp.gif"
            alt="404"
            fixSize={300}
          />
        </div>

        <div className="w-1/2 text-left">
          <div>
            <h3 className="text-2xl">Fehler 404 – der Panda findet die Seite nicht</h3>
            <p>
              Wir auch nicht – aber um eine neue Büroausstattung kümmern wir uns gerne. Rufen Sie
              uns einfach an!{' '}
            </p>
          </div>
          <div className="mt-10 flex">
            <div className="m-auto w-64 mx-2 ">
              <Link to="/">
                <button className="transition-ease-2 hover:bg-lendis-main-dark hover:border-lendis-main-dark my-16 bg-lendis-main border border-lendis-main font-semibold mt-6 px-4 py-4 rounded text-center w-full lg:mb-40">
                  <div className="items-center">
                    <p className="text-base text-white">STARTSEITE</p>
                  </div>
                </button>
              </Link>
            </div>

            <div className="m-auto w-64 mx-2">
              <Link to="/kontakt/">
                <button className="transition-ease-2 hover:bg-lendis-main-dark hover:border-lendis-main-dark my-16 bg-lendis-main border border-lendis-main font-semibold mt-6 px-4 py-4 rounded text-center w-full lg:mb-40">
                  <div className="items-center">
                    <p className="text-base text-white">KONTAKT</p>
                  </div>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </Layout>
)

export default NotFoundPage
